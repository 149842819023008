import { useEffect } from 'react'
import { useMapEvents } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import L from 'leaflet'
import {
  getNotificationMessage, markersSelectors, setIsUpdatingAvatar,
  updateMarkerAvatars, usersSelectors
} from 'store'
import { REJECTED } from 'utils/constants'
import { v4 as uuid } from 'uuid'

import { CopyMarkerItem } from './CopyMarkerItem'
import { MarkerItem } from './MarkerItem'

export const Markers = ({
  markers, setShowMarkerCount, storedId, setStoredId, mapRef
}) => {
  const { userProfile, activeProfile } = useSelector(usersSelectors)
  const dispatch = useDispatch()
  const {
    isFiltered, filters, markerAvatars, isUpdatingAvatar
  } = useSelector(markersSelectors)

  const mapItem = useMapEvents({
    moveend: () => {
      localStorage.setItem('northeast', JSON.stringify(mapItem?.getBounds().getNorthEast()))
      localStorage.setItem('southwest', JSON.stringify(mapItem?.getBounds().getSouthWest()))

      let northEast = L.latLng(JSON.parse(localStorage.getItem('northeast')))
      let southWest = L.latLng(JSON.parse(localStorage.getItem('southwest')))

      let markerId = []
      markers.forEach((item) => {
        if (markerAvatars.some((e) => e?.markerID === item?.id) === false) {
          if (
            item.coordinates[0] <= northEast.lat
            && item.coordinates[0] >= southWest.lat
            && item.coordinates[1] <= northEast.lng
            && item.coordinates[1] >= southWest.lng
          ) {
            if (item?.is_friends === 'accepted') {
              markerId.push(item?.id)
            }
          }
        }
      })

      if (markerId.length > 0 && isUpdatingAvatar === false) {
        dispatch(setIsUpdatingAvatar(true))
        setTimeout(() => dispatch(updateMarkerAvatars({ isAuth: true, markerId })), 500)
      }
    }
  })

  const generateAllMarkers = () => {
    const duplicatedMarkers = []

    markers.forEach((item) => {
      if (typeof item?.coordinates[0] === 'number' && typeof item?.coordinates[1] === 'number') {
        // dispatch(getMarkerAvatars({ isAuth: true, type: 'profile_picture', id: item?.id }))
        let imageBase64String = null

        markerAvatars?.forEach((e) => {
          if (item?.id === e.markerID) {
            imageBase64String = e.imageBase64
          }
          return null
        })

        const originalMarker = (
          <MarkerItem
            key={uuid()}
            profileAvatar={imageBase64String}
            item={item}
            setShowMarkerCount={setShowMarkerCount}
            storedId={storedId}
            setStoredId={setStoredId}
          />
        )

        duplicatedMarkers.push(originalMarker)

        // Duplicate all markers so even if the
        // screen is wide, all the markers will still be visible
        const trueLng = item.coordinates[1] > 0
          ? item.coordinates[1] - 360
          : item.coordinates[1] + 360

        const duplicatedMarker = (
          <CopyMarkerItem
            key={item.id}
            item={{ ...item, coordinates: [item.coordinates[0], trueLng] }}
            mapRef={mapRef}
            avatar={imageBase64String}
          />
        )
        duplicatedMarkers.push(duplicatedMarker)
      }
    })

    return duplicatedMarkers
  }

  // For adding own profile markers since it is not included in the function before this
  const generateOwnMarkers = () => {
    const geoLoc = true
    const isMyProfile = true
    const duplicatedMarkers = []

    userProfile?.forEach((item) => {
      if (item?.full_name && (typeof item?.coordinates[0] === 'number' && typeof item?.coordinates[1] === 'number')) {
        let imageBase64String = null

        userProfile?.forEach((e) => {
          if (item?.id === e?.id) {
            imageBase64String = e.profile_picture
          }
          return null
        })

        const originalMarker = (
          <MarkerItem
            key={uuid()}
            item={item}
            setShowMarkerCount={setShowMarkerCount}
            storedId={storedId}
            setStoredId={setStoredId}
            geoLoc={activeProfile?.full_name === item?.full_name ? geoLoc : false}
            isMyProfile={isMyProfile}
            profileAvatar={imageBase64String}
          />
        )

        duplicatedMarkers.push(originalMarker)

        const trueLng = item.coordinates[1] > 0
          ? item.coordinates[1] - 360
          : item.coordinates[1] + 360

        const duplicatedMarker = (
          <CopyMarkerItem
            key={item?.id}
            item={{ ...item, coordinates: [item.coordinates[0], trueLng] }}
            mapRef={mapRef}
            avatar={imageBase64String}
          />
        )
        duplicatedMarkers.push(duplicatedMarker)
      }
    })
    return duplicatedMarkers
  }

  const checkAffinities = (affinityList, filterList) => {
    const filterLenth = filterList.length
    let successCount = 0

    if (affinityList) {
      filterList.forEach((filter) => {
        if (affinityList?.includes(filter)) {
          successCount += 1
        }
      })
    }

    if (filterLenth === successCount) {
      return true
    }

    return false
  }

  const checkCodewords = (codewordsList, filterList) => {
    const filterLenth = filterList.length
    let successCount = 0
    filterList.forEach((filter) => {
      if (codewordsList?.includes(filter.toString())) {
        successCount += 1
      }
    })
    if (filterLenth === successCount) {
      return true
    }

    return false
  }

  // filtered markers
  const generateFilteredMarkers = (filter) => {
    const geoLoc = true
    const isMyProfile = true
    const duplicatedMarkers = []

    // console.log(filters)

    if (activeProfile?.full_name) {
      // handleVisibleMarkerCount()
      markers.forEach((item) => {
        if (
          checkAffinities(item?.affinities, filter?.affinities)
        && checkCodewords(item?.codewords?.map((codeword) => codeword), filter?.codewords)
        ) {
          if (typeof item?.coordinates[0] === 'number' && typeof item?.coordinates[1] === 'number') {
            let imageBase64String = null

            markerAvatars?.forEach((e) => {
              if (item?.id === e.markerID) {
                imageBase64String = e.imageBase64
              }
              return null
            })

            userProfile?.forEach((e) => {
              if (item?.id === e.id) {
                imageBase64String = e.profile_picture
              }
              return null
            })

            const originalMarker = (
              <MarkerItem
                key={uuid()}
                item={item}
                setShowMarkerCount={setShowMarkerCount}
                storedId={storedId}
                setStoredId={setStoredId}
                profileAvatar={imageBase64String}
              />
            )

            duplicatedMarkers.push(originalMarker)

            // Duplicate all markers so even if
            // the screen is wide, all the markers will still be visible
            const trueLng = item.coordinates[1] > 0
              ? item.coordinates[1] - 360
              : item.coordinates[1] + 360

            const duplicatedMarker = (
              <CopyMarkerItem
                key={item.id}
                item={{ ...item, coordinates: [item.coordinates[0], trueLng] }}
                mapRef={mapRef}
                avatar={imageBase64String}
              />
            )
            duplicatedMarkers.push(duplicatedMarker)
          }
        }
      })

      userProfile?.forEach((item) => {
        if (item?.full_name
        && (
          checkAffinities(item?.affinities?.map((affinity) => affinity?.title), filter?.affinities)
          && checkCodewords(item?.code_words?.map((codeword) => codeword?.title), filter?.codewords)
        )
        ) {
          if (typeof item?.coordinates[0] === 'number' && typeof item?.coordinates[1] === 'number') {
            let imageBase64String = null

            userProfile?.forEach((e) => {
              if (item?.id === e?.id) {
                imageBase64String = e.profile_picture
              }
              return null
            })
            const originalMarker = (
              <MarkerItem
                key={uuid()}
                item={item}
                profileAvatar={imageBase64String}
                setShowMarkerCount={setShowMarkerCount}
                storedId={storedId}
                setStoredId={setStoredId}
                geoLoc={activeProfile?.full_name === item?.full_name ? geoLoc : false}
                isMyProfile={isMyProfile}
              />
            )

            duplicatedMarkers.push(originalMarker)

            const trueLng = item.coordinates[1] > 0
              ? item.coordinates[1] - 360
              : item.coordinates[1] + 360

            const duplicatedMarker = (
              <CopyMarkerItem
                key={item?.id}
                item={{ ...item, coordinates: [item.coordinates[0], trueLng] }}
                mapRef={mapRef}
                avatar={imageBase64String}
              />
            )
            duplicatedMarkers.push(duplicatedMarker)
          }
        }
      })
    } else {
      dispatch(getNotificationMessage({
        type: REJECTED,
        message: 'Create a profile first before adding a filter.',
        isEditProfile: true
      }))
    }

    return duplicatedMarkers
  }

  useEffect(() => {
    if (isFiltered === true) {
      generateFilteredMarkers(filters)
    }
  }, [filters])

  useEffect(() => {
    generateAllMarkers()
    generateOwnMarkers()
  }, [markerAvatars])

  return (
    <>
      {isFiltered === true ? null : generateAllMarkers()}
      {isFiltered === true ? null : generateOwnMarkers()}
      {isFiltered === true ? generateFilteredMarkers(filters) : null}
      {/* TODO client asked to comment */}
      {/* <MyPosition activeProfile={activeProfile}/> */}
    </>
  )
}
