import { useRef, useEffect, useState } from 'react'
import {
  MapContainer, Marker, TileLayer, useMapEvent
} from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react'
import Leaflet from 'leaflet'
import { ImagesPng } from 'assets/images'
import { setIsLocationOpen, setUserPosition, usersSelectors } from 'store'
import {
  MAP_ATTRIBUTION,
  MAP_URL,
  ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  UNSUPPORTED_MAX_ZOOM_LEVEL
} from 'utils/constants'
import { iconMarkerImage } from 'utils/helpers'
import { COLORS } from 'utils/styles'

export const MapModal = ({
  userPosition, isStaticLocation, tempLocationData, full_name
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { randomPos } = useSelector(usersSelectors)
  const [userRandomPos, setUserRandomPos] = useState(randomPos)
  const [isPosSelected, setIsPosSelected] = useState(false)

  const dispatch = useDispatch()

  let html = '<img src="' + ImagesPng.DefaultImage + '" width="27px" height="27px" marginTop="5px" class="avatar-location-picker" alt="marker" /><img src="' + ImagesPng.PrimaryMarker + '" width="27.5px" height="46px" class="marker-image-picker" alt="marker" />'

  const NumberBreakdown = (param) => {
    let arr = (param + '').split('.')
    return arr[0] + '.' + arr[1].substring(0, 6)
  }

  // custom arrow marker for location picker
  // const customArrowPointer = () => {
  //   const arrowPointer = `<img src="${ImagesPng.WhiteArrow}"
  // width="27.5px" height="46px" class="marker-image-picker" alt="marker" />`
  //   return arrowPointer
  // }

  const LocationFinderDummy = () => {
    useMapEvent('click', (e) => {
      const lat = NumberBreakdown(e.latlng.lat)
      const lng = NumberBreakdown(e.latlng.wrap().lng)
      setIsPosSelected(true)
      dispatch(setUserPosition([lat, lng]))
      dispatch(setIsLocationOpen(true))
    })
    return null
  }

  const handleOpenLocation = () => {
    dispatch(setIsLocationOpen(true))
    onOpen()
  }

  const handleCloseLocation = () => {
    dispatch(setIsLocationOpen(false))
    onClose()
  }

  useEffect(() => {
    setUserRandomPos(randomPos)
  }, [randomPos])

  const northBound = Leaflet.latLng(85, Infinity)
  const southBound = Leaflet.latLng(-85, -Infinity)
  const fullBound = Leaflet.latLngBounds(northBound, southBound)

  return (
    <>
      {isStaticLocation ? <Button onClick={handleOpenLocation} data-name="chooseStaticLocation">Choose Static Location</Button> : null}

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleCloseLocation}
      >
        <ModalOverlay />

        <ModalContent width="500px" height="500px">
          <ModalHeader>Mark your position</ModalHeader>
          <ModalCloseButton name="close-location-modal" />
          <ModalBody pb={6}>
            <MapContainer
              style={{ width: '100%', height: '100%' }}
              center={userPosition || tempLocationData}
              zoom={ZOOM_LEVEL}
              minZoom={1}
              worldCopyJump
              maxBounds={fullBound}
              maxBoundsViscosity={1.0}
              className="mapmodal"
            >
              <LocationFinderDummy />

              <TileLayer
                attribution={MAP_ATTRIBUTION}
                url={MAP_URL}
                maxNativeZoom={MAX_ZOOM_LEVEL}
                maxZoom={UNSUPPORTED_MAX_ZOOM_LEVEL}
              />

              {userPosition ? (
                <Marker
                  icon={iconMarkerImage(false, html)}
                  position={
                    isStaticLocation
                    && full_name === null
                    && isPosSelected === false
                    && (Number.isNaN(randomPos?.lat) === false
                    || Number.isNaN(randomPos?.lng) === false
                    ) ? userRandomPos : userPosition
                  }
                />
              ) : null}
            </MapContainer>
          </ModalBody>

          <ModalFooter>
            <Button name="confirm-location" background={COLORS.gold} mr={3} onClick={handleCloseLocation}>
              CONTINUE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
