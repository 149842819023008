import {
  Box, Button, Center, Flex, Grid, GridItem, Spacer, Stack, Text
} from '@chakra-ui/react'
import { PlusSignIcon } from 'assets/icons'

export const EventSection = ({
  isPending
}) => {
  const comingSoon = (showPrompt) => (
    <Text fontSize="14px">
      { showPrompt ? 'Coming soon' : '' }
    </Text>
  )

  return (
    <Stack
      direction="column"
      width="calc(100% + 100px)"
      spacing="12px"
      bgColor="white"
      sx={{
        marginLeft: '-50px !important',
        paddingY: '1rem'
      }}
      borderRadius="5px"
    >
      <Center>
        <Stack direction="column" width="320px" className="profile-container" spacing="1rem" bgColor="white">
          <Stack
            spacing="48%"
            alignItems="center"
            direction="row"
            w="100%"
            sx={{
              pointerEvents: 'none',
              opacity: 0.5,
              gap: '48%',
              '@media (max-width: 480px)': {
                gap: '35%',
                marginLeft: '50px'
              }
            }}
          >
            {/* TODO client asked to comment */}
            {/* <Box>{icon}</Box> */}
            <Text fontWeight={700} fontSize="20px">
              Events
            </Text>
            <Stack direction="row">
              <Text
                fontWeight={700}
                fontSize="20px"
                right={10}
                width="max-content !important"
                sx={{
                  '@media (max-width: 480px)': {
                    marginLeft: '-48px'
                  }
                }}
              />
              { comingSoon(isPending) }
            </Stack>
          </Stack>

          <Button
            // bgGradient="linear(to-t, #fff, #F4E69B)"
            // _hover={{
            //   bgGradient: 'linear(to-t, #fff, #F4E69B)'
            // }}
            bgColor="#e6e6e6"
            sx={{ opacity: 0.5 }}
            h="40px"
          >
            <Flex width="100%">
              <Box />
              <Spacer />
              <Text fontSize="16px">
                I want to attend...
              </Text>
              <Spacer />
              <PlusSignIcon />
            </Flex>
          </Button>
          <Grid
            templateColumns="repeat(2, 1fr)"
            height="100px"
            // overflowX="scroll"
            border="1px solid black"
            p="3px"
            gap={0}
            opacity={0.5}
            fontSize="16px"
            h="100px"
          >
            {/* For Future Use */}
            <GridItem>
              Event 1
            </GridItem>
            <GridItem align="right">
              Type
            </GridItem>
          </Grid>

        </Stack>
      </Center>

    </Stack>
  )
}
