import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  getEditProfileMessage,
  setIsChangesSaved,
  setIsProfileIncomplete,
  setIsSettingPage,
  setUserSectionPage
} from 'store'
import {
  EDIT_PROFILE, MY_PROFILES, SETTINGS, SINGLE_PROFILE
} from 'utils/constants'

export const DiscardChanges = ({
  isDiscard,
  setIsDiscard,
  setIsCloseable,
  isProfileActive,
  isProfileIncomplete,
  isSetting,
  setIsSetting,
  isClickedOutside,
  setIsClickedOutside,
  setCloseTheModal
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const finalRef = useRef(null)
  const [isIncomplete, setIsIncomplete] = useState(false)

  const dispatch = useDispatch()

  const handleDiscardChanges = () => {
    if (isProfileIncomplete === true) {
      setIsIncomplete(true)
    } else {
      dispatch(getEditProfileMessage({
        message1: [],
        message2: []
      }))
      setIsDiscard(false)
      dispatch(setIsProfileIncomplete(false))
      setIsCloseable(true)
      dispatch(setIsChangesSaved(true))
      if (isSetting === true) {
        dispatch(setIsSettingPage(true))
        setIsSetting(false)
        onClose()
      } else {
        dispatch(setIsSettingPage(false))
        setIsSetting(false)
        onClose()
      }
    }
  }

  const handleIncompleteChanges = () => {
    setIsDiscard(false)
    setIsCloseable(false)
    setIsIncomplete(false)
    onClose()
    dispatch(setUserSectionPage(EDIT_PROFILE))
  }

  const handleCloseDiscard = () => {
    setIsDiscard(false)
    setIsCloseable(true)
    dispatch(setIsProfileIncomplete(false))
    dispatch(getEditProfileMessage({
      message1: [],
      message2: []
    }))

    if (isSetting === true) {
      setIsClickedOutside(true)
      setIsSetting(false)
      dispatch(setUserSectionPage(SETTINGS))
      onClose()
    } else if (isClickedOutside === true) {
      setCloseTheModal(true)
      dispatch(setUserSectionPage(MY_PROFILES))
    } else {
      setIsClickedOutside(true)

      if (isProfileActive === true) {
        dispatch(setUserSectionPage(SINGLE_PROFILE))
      } else {
        dispatch(setUserSectionPage(MY_PROFILES))
      }
      onClose()
    }
  }

  const handleCloseButtonDiscard = () => {
    setIsSetting(false)
    setIsClickedOutside(true)
    setIsDiscard(false)
    setIsCloseable(false)
    dispatch(setUserSectionPage(EDIT_PROFILE))
    onClose()
  }

  useEffect(() => {
    if (isDiscard === true) {
      onOpen()
    }
  }, [isDiscard])

  return (
    <Modal isCentered finalFocusRef={finalRef} isOpen={isOpen} onClose={handleCloseButtonDiscard}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Save Changes</ModalHeader>

        <ModalBody>
          {isIncomplete === true
            ? <Text>Your profile is incomplete</Text>
            : <Text>Are you sure you want to save your changes?</Text>}
        </ModalBody>

        <ModalFooter>
          {isIncomplete === true
            ? (
              <Button
                // isLoading={deleteProfileStatus === PENDING}
                colorScheme="yellow"
                name="reset-yes"
                mr={3}
                onClick={() => handleIncompleteChanges()}
              >
                Ok
              </Button>
            ) : (
              <>
                <Button
                  //   isLoading={deleteProfileStatus === PENDING}
                  colorScheme="yellow"
                  name="reset-yes"
                  mr={3}
                  onClick={() => handleDiscardChanges()}
                >
                  Yes
                </Button>
                <Button mr={3} onClick={() => handleCloseDiscard()} name="reset-no">
                  No
                </Button>
              </>
            )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
