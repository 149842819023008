import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SingleButton } from 'common/Buttons'
import { StyledButtonContainer } from 'common/Buttons/SingleButton/styled'
import {
  getEditProfileMessage,
  getNotificationMessage,
  handleModalTitle,
  setUserPosition,
  updateUserProfile
} from 'store'
import {
  REJECTED, USER_NAME, VERIFY_PAGE, LOCATION_TYPE
} from 'utils/constants'
import { EditProfileValidationScheme } from 'validation'

import { Affinities } from 'components/UserSection/EditProfile/Affinities'
import { CodeWords } from 'components/UserSection/EditProfile/CodeWords'
// import { Connections } from 'components/UserSection/EditProfile/Connections'
import { Location } from 'components/UserSection/EditProfile/Location'
import { SocialProfiles } from 'components/UserSection/EditProfile/SocialProfiles'
import { UserInformation } from 'components/UserSection/EditProfile/UserInformation'

import {
  getMarkers,
  setIsChangesSaved,
  setIsProfileChanged,
  setIsProfileIncomplete,
  setUserEdited,
  usersSelectors
} from '../../../store'

import { ResetProfile } from './ResetProfile'

export const EditProfile = ({
  selectedUser,
  searchSocialLinks,
  searchSocialLinksCategories,
  isLoading,
  deleteProfileStatus,
  userPosition,
  croppedPicture,
  affinityList
}) => {
  const {
    id,
    code_words,
    profile_picture,
    location_type,
    affinities,
    social_links,
    profile_type,
    full_name,
    personal_quote,
    banner,
    coordinates,
    connections_visibility
  } = selectedUser

  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(EditProfileValidationScheme)
  })

  const { isChangesSaved } = useSelector(usersSelectors)

  const [codeWordsArray, setCodeWordsArray] = useState([])
  const [affinitiesArray, setAffinitiesArray] = useState([])
  const [socialLinksArray, setSocialLinksArray] = useState(social_links)
  const [isDeleteImage, setIsDeleteImage] = useState(false)
  const [isDeleteImage2, setIsDeleteImage2] = useState(false)
  const [isSocialLinkSelected, setIsSocialLinkSelected] = useState(false)
  const [connectWallet, setConnectWallet] = useState('Connect Wallet')
  const [incomplete, setIncomplete] = useState(false)
  const [userQuote, setUserQuote] = useState(personal_quote)
  const [nameChanges, setNameChanges] = useState(full_name)
  // eslint-disable-next-line no-unused-vars
  const [isShowConnections, setIsShowConnections] = useState(connections_visibility)
  const [selectLocation, setSelectLocation] = useState(
    location_type ? Object.values(location_type)[0] : 0
  )
  const [profilePicture, setProfilePicture] = useState({
    image: '',
    imagePath: profile_picture
  })
  const [coverPicture, setCoverPicture] = useState({
    image: '',
    imagePath: banner
  })

  // This function will be removed after testing, check Header/index.jsx line 135-147
  // for new function location
  // if (process.env.REACT_APP_NODE_ENV !== 'CORDOVA_APPLICATION') {
  //   navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
  //     permissionStatus.onchange = () => {
  //       if (permissionStatus.state === 'denied') {
  //         localStorage.setItem('isLocDisabled', true)
  //       } else {
  //         localStorage.setItem('isLocDisabled', false)
  //       }
  //     }
  //   })
  // }

  const handleWallet = () => {
    setConnectWallet('Coming Soon')
    setTimeout(() => setConnectWallet('Connect Wallet'), 2000)
  }

  const onSubmit = (data) => {
    const isLocDisabled = localStorage.getItem('isLocDisabled')
    dispatch(getEditProfileMessage({
      message1: [],
      message2: []
    }))
    // If the user is using static location but didn't enter a location
    if (((selectLocation === 2 || selectLocation === '2') && userPosition === null) || affinitiesArray.length <= 0 || data.userName.length <= 0) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'You are missing a location, affinity or profile name',
          isEditProfile: true
        })
      )
      setIncomplete(true)
      setTimeout(() => setIncomplete(false), 2000)
    } else if ((isLocDisabled === 'true' && (selectLocation === 0 || selectLocation === '0' || selectLocation === 1 || selectLocation === '1'))
      || 'geolocation' in navigator === false) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'You cannot use "' + LOCATION_TYPE.LiveLocation + '" or "' + LOCATION_TYPE.LiveApproximate + '" because you have disabled your browser location permission.',
          isEditProfile: true
        })
      )
    } else {
      const userData = {
        id,
        profile_type,
        affinities: affinitiesArray.map((item) => item.id),
        code_words: codeWordsArray.map((item) => item.title),
        location_type: selectLocation,
        full_name: data.userName,
        personal_quote: userQuote,
        socialLinks: socialLinksArray,
        coordinates: userPosition
      }

      const switcherData = {
        is_visible: +isShowConnections
      }

      const formData = new FormData()
      const formData2 = new FormData()

      if (profilePicture.image && coverPicture.image) {
        formData.append('profile_picture', profilePicture.image)
        formData2.append('banner', coverPicture.image)
        dispatch(
          updateUserProfile({
            userData,
            formData,
            formData2,
            id,
            switcherData
          })
        )
        // dispatch(setIsProfileChanged(false))
        dispatch(setUserEdited(selectedUser))
      } else if (profilePicture.image && !isDeleteImage2) {
        formData.append('profile_picture', profilePicture.image)
        dispatch(
          updateUserProfile({
            userData,
            formData,
            id,
            switcherData
          })
        )
        // dispatch(setIsProfileChanged(false))
        dispatch(setUserEdited(selectedUser))
      } else if (!isDeleteImage && coverPicture.image) {
        formData2.append('banner', coverPicture.image)
        dispatch(
          updateUserProfile({
            userData,
            formData2,
            id,
            switcherData
          })
        )
        // dispatch(setIsProfileChanged(false))
        dispatch(setUserEdited(selectedUser))
      } else {
        dispatch(updateUserProfile({ userData, id, switcherData }))
        // dispatch(setIsProfileChanged(false))
        dispatch(setUserEdited(selectedUser))
      }
    }
    setTimeout(() => dispatch(getMarkers({ isAuth: true })), 1000)
  }

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.EditProfile))
    setCodeWordsArray(code_words)
    setAffinitiesArray(affinities)
    dispatch(setUserPosition(coordinates))
  }, [])

  // To check if there are changes made
  useEffect(() => {
    let originalLocType = '0'
    if (selectedUser.location_type !== undefined && selectedUser.location_type !== null) {
      const keys = Object.keys(selectedUser.location_type)

      if (keys.includes(LOCATION_TYPE.LiveApproximate)) {
        originalLocType = '1'
      } else if (keys.includes(LOCATION_TYPE.StaticLocation)) {
        originalLocType = '2'
      }
    }

    let originalCoordinates = ''
    if (selectedUser.coordinates === undefined || selectedUser.coordinates === null) {
      originalCoordinates = {
        0: localStorage.getItem('lat'),
        1: localStorage.getItem('lng')
      }
    } else {
      originalCoordinates = {
        0: selectedUser.coordinates[0],
        1: selectedUser.coordinates[1]
      }
    }

    if (
      (
        nameChanges === full_name
        || (
          nameChanges === ''
          && full_name === null
        )
      ) && (
        userQuote === personal_quote
        || (
          userQuote === ''
          && personal_quote === null
        )
      ) && JSON.stringify(codeWordsArray) === JSON.stringify(code_words)
      && JSON.stringify(affinitiesArray) === JSON.stringify(affinities)
      && JSON.stringify(socialLinksArray) === JSON.stringify(social_links)
      && JSON.stringify(profilePicture.imagePath) === JSON.stringify(profile_picture)
      && JSON.stringify(coverPicture.imagePath) === JSON.stringify(banner)
      && (
        (userPosition
        && String(userPosition[0]) === String(originalCoordinates[0])
        && String(userPosition[1]) === String(originalCoordinates[1])
        && String(selectLocation) === originalLocType)
        || (coordinates === null && String(selectLocation) === originalLocType)
      )
    ) {
      dispatch(setIsProfileChanged(false))
      dispatch(setIsProfileIncomplete(false))
    } else {
      dispatch(setIsProfileChanged(true))
      if (nameChanges === ''
        || affinitiesArray.length <= 0
        || ((selectLocation === 2 || selectLocation === '2') && userPosition === null)
      ) {
        dispatch(setIsProfileIncomplete(true))
      } else {
        dispatch(setIsProfileIncomplete(false))
      }
    }
  }, [
    nameChanges,
    userQuote,
    codeWordsArray,
    affinitiesArray,
    userPosition,
    socialLinksArray,
    profilePicture,
    coverPicture,
    selectLocation
    // isSocialLinkSelected
  ])

  // To submit if save changed prompt is accepted
  useEffect(() => {
    if (isChangesSaved === true) {
      handleSubmit(onSubmit)()
        .then(
          dispatch(setIsChangesSaved(false))
        )
    }
  }, [isChangesSaved])

  return (
    <Box>
      <Stack direction="column" spacing="16px">
        <UserInformation
          setIsDeleteImage={setIsDeleteImage}
          setIsDeleteImage2={setIsDeleteImage2}
          setProfilePicture={setProfilePicture}
          setCoverPicture={setCoverPicture}
          profilePicture={profilePicture}
          coverPicture={coverPicture}
          error={errors.userName}
          register={register(USER_NAME)}
          value={full_name}
          setNameChanges={setNameChanges}
          quote={userQuote}
          setQuote={setUserQuote}
          setIncomplete={setIncomplete}
          croppedPicture={croppedPicture}
        />
        {/* <Connections
          isShowConnections={isShowConnections}
          setIsShowConnections={setIsShowConnections}
          activeConnections={active_connections}
          pastConnections={past_connections}
        /> */}
        <CodeWords codeWordsArray={codeWordsArray} setCodeWordsArray={setCodeWordsArray} />
        <Affinities
          affinitiesArray={affinitiesArray}
          setAffinitiesArray={setAffinitiesArray}
          setIncomplete={setIncomplete}
          incomplete={incomplete}
          affinityList={affinityList}
        />
        <SocialProfiles
          socialLinksArray={socialLinksArray}
          setSocialLinksArray={setSocialLinksArray}
          searchSocialLinks={searchSocialLinks}
          searchSocialLinksCategories={searchSocialLinksCategories}
          setIsSocialLinkSelected={setIsSocialLinkSelected}
          isSocialLinkSelected={isSocialLinkSelected}
        />
        <StyledButtonContainer
          name="connect-wallet"
          onClick={() => handleWallet()}
          style={{
            backgroundColor: '#e6e6e6'
            // boxShadow: '1px 1px 0px 0px #737373'
          }}
        >
          {connectWallet}
        </StyledButtonContainer>
        <Location
          setSelectLocation={setSelectLocation}
          location_type={location_type}
          userPosition={userPosition}
          name="profile_location"
          incomplete={incomplete}
          full_name={full_name}
          coordinates={coordinates}
          id={id}
        />

        {incomplete === true
          ? (
            <StyledButtonContainer disabled="true" text="Incomplete">
              <Text color="red">
                Incomplete
              </Text>
            </StyledButtonContainer>
          )
          : <SingleButton name="save-changes" click={handleSubmit(onSubmit)} isLoading={isLoading} text="Save changes" disabled="true" />}

        {/* <SingleButton click={handleSubmit(onSubmit)}
        isLoading={isLoading} text="Save changes" /> */}

        <ResetProfile deleteProfileStatus={deleteProfileStatus} id={id} />
      </Stack>
    </Box>
  )
}
