import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useOutsideClick
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { setProfileItemId, showCurrentChatFriendMarkerCoordinate } from 'store'
import { howManyDayAgo } from 'utils/helpers'
import { COLORS } from 'utils/styles'

export const ContentModal = ({
  item, isOpen, onClose, title, isNotifModalOpen
}) => {
  const dispatch = useDispatch()

  const [description, setDescription] = useState()
  const link = item?.link
  const modalRef = useRef()

  const GotoMarker = (profileId, coordinates) => {
    dispatch(setProfileItemId(''))
    let coord = {
      coord: coordinates,
      isStatic: true
    }
    dispatch(showCurrentChatFriendMarkerCoordinate(coord))
    document.querySelector('#back-button').click()
    localStorage.setItem('openProfileAfterAnimation', profileId)
  }

  const setupDescription = () => {
    if (item?.related_to === null) {
      if (item?.link === null) {
        setDescription(item?.description ?? '-')
      } else {
        setDescription(
          <>
            {item?.description}
            <b />
            {title === 'connector' ? null : (
              <Stack m={5}>
                <Box
                  type="button"
                  name="open-link"
                  onClick={() => window.open(item?.link, '_blank')}
                  background={COLORS.gold}
                  padding="4px 12px"
                  borderRadius="5px"
                  left="50%"
                  textAlign="center"
                  cursor="pointer"
                >
                  {item?.link_title}
                </Box>
              </Stack>
            ) }
          </>
        )
      }
    } else if (
      item?.related_to === 'new profile'
      || item?.related_to === 'profile new affinity'
    ) {
      const profileId = item?.description?.profile_id
      const fullName = item?.description?.full_name
      const affinities = item?.description?.affinities
      const coordinates = item?.description?.coordinates
      let body = ''

      if (item?.related_to === 'new profile') {
        body = 'is a new user with an affinity for'
      } else {
        body = 'now has an affinity for'
      }

      setDescription(
        <>
          <b>{fullName}</b>
          &nbsp;
          {body}
          &nbsp;
          <b>{affinities}</b>
          .
          <Stack m={5}>
            <Box
              type="button"
              name="go-to-profile-marker"
              onClick={() => GotoMarker(
                profileId,
                coordinates
              )}
              background={COLORS.gold}
              padding="4px 12px"
              borderRadius="5px"
              left="50%"
              textAlign="center"
              cursor="pointer"
            >
              {`Click here to see ${fullName} on the map`}
            </Box>
          </Stack>
        </>
      )
    }
  }

  useEffect(() => {
    if (isOpen) {
      localStorage.removeItem('id')
    }
  }, [isOpen])

  useEffect(() => {
    setupDescription()
  }, [item])

  useOutsideClick({
    ref: modalRef,
    handler: () => {
      if (isNotifModalOpen === true) {
        onClose()
      }
    }
  })

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent
        ref={modalRef}
        className={title === 'connector' ? 'connector-modal' : 'notif-modal'}
        sx={{
          ':connector-modal': {
            maxWidth: '650px !important',
            '@media (max-width: 600px)': {
              maxWidth: '450px'
            }
          }
        }}
      >
        <ModalHeader>
          <Stack direction="row">
            <Image
              boxSize="60px"
              objectFit="cover"
              src={item?.image || ImagesPng.DefaultImage}
              alt={item?.title}
              borderRadius="full"
            />

            <Stack>
              <Stack>
                <Text fontWeight={600} data-name="notificationModalTitle">{item?.title}</Text>
              </Stack>

              <Text fontSize="12px" color={COLORS.gray}>
                {howManyDayAgo(item?.data)}
              </Text>
            </Stack>
          </Stack>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          {title === 'connector' ? (
            <Stack>
              <iframe id="odysee-iframe" title="videoNotification" width="100%" height="315" src={link} allowfullscreen />
            </Stack>
          ) : null}
          {description}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
