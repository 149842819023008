import { Box, Flex } from '@chakra-ui/react'
import { GlobeIcon } from 'assets/icons'
import { COLORS } from 'utils/styles'

export const UsersCount = ({
  visibleMarkersCount, showMarkerCount, zoom, isFiltered
}) => (
  <Flex
    zIndex={400}
    position="absolute"
    top="5px"
    fontSize="14px"
    fontWeight="bold"
    background={COLORS.gray_op8}
    padding="4px 12px"
    borderRadius="5px"
    left="49%"
    display={showMarkerCount}
    flexDirection="row"
    gap={1}
    data-name="markerCount"
    className="marker-count"
  >
    {visibleMarkersCount?.length}
    {zoom === '4' && isFiltered === 'false' ? (
      <Box marginTop="1px">
        <GlobeIcon />
      </Box>
    )
      : null}
  </Flex>
)
